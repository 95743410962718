var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("psi-action-drop-down", {
    attrs: {
      "min-width": 320,
      items: _vm.menuItemActions,
      heading: "Start Applications"
    },
    on: { action: _vm.handleAction }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }