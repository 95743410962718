<template>
    <psi-action-drop-down
        :min-width="320"
        :items="menuItemActions"
        heading="Start Applications"
        @action="handleAction"
    ></psi-action-drop-down>
</template>
<script>
import ApplicantDisplay from "@components/uploads/mixins/ApplicantDisplay";
export default {
    name: "application-list-actions",
    components: {},
    mixins: [ApplicantDisplay],
    props: {
        applicants: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        menuItemActions() {
            return this.applicants.map((applicant) => {
                const { icon, textClass } = this.applicantDisplay(
                    applicant.type
                );
                return {
                    ...applicant,
                    title: applicant.meta.name,
                    icon,
                    class: textClass,
                };
            });
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        handleAction(applicant) {
            const url = this.buildApplyUrl(applicant.meta.params);
            window.location.replace(url);
        },
    },
};
</script>

<style scoped>
</style>